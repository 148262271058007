import { type FC, type ChangeEventHandler, useCallback, useState, type FormEvent } from 'react';
import { Input } from 'dibs-elements/exports/Input';
import { useIntl, defineMessages } from 'dibs-react-intl';
import MagnifyingGlass from 'dibs-icons/exports/legacy/MagnifyingGlass';
import { type SidebarProps } from '../Sidebar/Sidebar';

import styles from './SidebarSearchBox.scss';

// Tracking
import { trackEvent } from 'dibs-tracking';

const messages = defineMessages({
    searchInputPlaceholder: {
        id: 'abm.SellerDirectory.searchInputPlaceholder',
        defaultMessage: 'Search for sellers',
    },
});

const SidebarSearchBox: FC<SidebarProps> = ({
    company = '',
    onChangeCompany,
    isTopSeller = false,
}) => {
    const [searchValue, setSearchValue] = useState<string>(company || '');
    const intl = useIntl();
    const onChange: ChangeEventHandler<HTMLInputElement> = event =>
        setSearchValue(event.currentTarget.value);
    const onSubmit = useCallback(
        (e: FormEvent) => {
            e.preventDefault();
            onChangeCompany(searchValue);
            trackEvent({
                action: 'search within results',
                category: 'dealer directory page refinement',
            });
            // return false to skip the page reload
            return false;
        },
        [searchValue, onChangeCompany]
    );

    return (
        <form onSubmit={onSubmit}>
            <Input
                dataTn="searchDealer"
                errorDataTn="input-error-data-tn"
                placeholder={intl.formatMessage(messages.searchInputPlaceholder)}
                value={searchValue}
                onChange={onChange}
                rightDecorator={<MagnifyingGlass className={styles.icon} />}
                size="medium"
                disabled={isTopSeller}
            />
        </form>
    );
};

export default SidebarSearchBox;
