import { type FC } from 'react';
import Sidebar, { type SidebarProps } from './Sidebar';
import styles from './SidebarContainer.scss';

const SidebarContainer: FC<SidebarProps> = props => (
    <nav className="colXs12 colLg3">
        <div className={styles.container}>
            <Sidebar {...props} />
        </div>
    </nav>
);

export default SidebarContainer;
