import serverVars from 'server-vars';
import { hydrateRoot } from 'react-dom/client';
import { QueryRenderer } from 'react-relay/legacy';

import { FormattedMessage } from 'dibs-react-intl';
import { type DibsRelayRenderProps } from 'dibs-relay-network-layer';
import { OverlaySpinner } from 'dibs-elements/exports/OverlaySpinner';

import { getRelayEnvironment } from '../utils/relayModernEnvironment';
import * as tracking from 'dibs-tracking';
import { IntlProvider } from 'dibs-react-intl';
import { initializeDBL, initializeTracking } from 'dibs-buyer-layout/src/entries/index';
import SellerDirectoryLayout from '../sellerDirectory/SellerDirectoryLayout';
import sellerDirectoryRoot from '../sellerDirectory/sellerDirectoryRoot';
import {
    type sellerDirectoryRootQuery,
    type sellerDirectoryRootQuery$variables,
} from '../sellerDirectory/__generated__/sellerDirectoryRootQuery.graphql';

const devicetype = serverVars.get('devicetype');
const variables: sellerDirectoryRootQuery$variables = serverVars.get('relayVariables');
const environment = getRelayEnvironment(serverVars.get('dbl.relayData'));

initializeDBL().then(() => {
    hydrateRoot(
        document.getElementById('js-root') as HTMLElement,
        <IntlProvider locale={serverVars.get('locale')} messages={serverVars.get('messages')}>
            <QueryRenderer<sellerDirectoryRootQuery>
                environment={environment}
                fetchPolicy="store-and-network"
                query={sellerDirectoryRoot}
                variables={variables}
                render={({ props }: DibsRelayRenderProps<sellerDirectoryRootQuery>) => {
                    if (!props) {
                        return (
                            <FormattedMessage
                                id="abm.sellerDirectory.loadError"
                                defaultMessage="An error occurred.  Please try again later."
                            />
                        );
                    }

                    if (!props.viewer) {
                        return <OverlaySpinner isOpen />;
                    }

                    return (
                        <SellerDirectoryLayout
                            viewer={props.viewer}
                            devicetype={devicetype}
                            {...variables}
                        />
                    );
                }}
            />
        </IntlProvider>
    );
    initializeTracking();
    tracking.trackPageview();
});
