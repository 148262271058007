import dibsCss from 'dibs-css';
import classnames from 'classnames';
import { type FunctionComponent } from 'react';

type PlusProps = {
    className?: string;
};

const Plus: FunctionComponent<PlusProps> = props => {
    return (
        <svg
            className={classnames(dibsCss.dibsIcon, props.className)}
            data-tn={'plus'}
            viewBox="0 0 250 250"
        >
            <path d="M117 118H7.499A7.499 7.499 0 0 0 0 125.5c0 4.134 3.357 7.5 7.499 7.5H117v109.501a7.499 7.499 0 0 0 7.5 7.499c4.134 0 7.5-3.357 7.5-7.499V133h110.501a7.499 7.499 0 0 0 7.499-7.5c0-4.134-3.357-7.5-7.499-7.5H132V7.499A7.499 7.499 0 0 0 124.5 0c-4.134 0-7.5 3.357-7.5 7.499V118z" />
        </svg>
    );
};
export default Plus;
