/**
 * @generated SignedSource<<65d46b8d9787deaca56a4d97e5c59f2e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SellerTile_seller$data = {
  readonly dealerSince: string | null;
  readonly isDiamondSeller: boolean | null;
  readonly isDistinguished: boolean | null;
  readonly isTopSeller: boolean | null;
  readonly sellerPreferences: {
    readonly urlLabel: string | null;
  } | null;
  readonly sellerProfile: {
    readonly company: string | null;
  } | null;
  readonly shippingAddress: {
    readonly displayCityStateCountry: string | null;
  } | null;
  readonly storefrontProfiles: ReadonlyArray<{
    readonly banner: {
      readonly path: string | null;
    } | null;
    readonly logo: {
      readonly path: string | null;
    } | null;
  } | null> | null;
  readonly " $fragmentType": "SellerTile_seller";
};
export type SellerTile_seller$key = {
  readonly " $data"?: SellerTile_seller$data;
  readonly " $fragmentSpreads": FragmentRefs<"SellerTile_seller">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "path",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SellerTile_seller",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "SellerProfile",
      "kind": "LinkedField",
      "name": "sellerProfile",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "company",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "SellerPreferences",
      "kind": "LinkedField",
      "name": "sellerPreferences",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "urlLabel",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Address",
      "kind": "LinkedField",
      "name": "shippingAddress",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "displayCityStateCountry",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "StorefrontProfile",
      "kind": "LinkedField",
      "name": "storefrontProfiles",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Photo",
          "kind": "LinkedField",
          "name": "logo",
          "plural": false,
          "selections": (v0/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Photo",
          "kind": "LinkedField",
          "name": "banner",
          "plural": false,
          "selections": (v0/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "dealerSince",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isDistinguished",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isDiamondSeller",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isTopSeller",
      "storageKey": null
    }
  ],
  "type": "Seller",
  "abstractKey": null
};
})();

(node as any).hash = "066e268e21395201a188f09e52e01e52";

export default node;
