/**
 * @generated SignedSource<<ef864a9c9f22f931e9b00fc1cd50109c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SellerDirectoryLayout_viewer$data = {
  readonly " $fragmentSpreads": FragmentRefs<"SellerDirectoryContentModule_viewer" | "SellersContainer_viewer">;
  readonly " $fragmentType": "SellerDirectoryLayout_viewer";
};
export type SellerDirectoryLayout_viewer$key = {
  readonly " $data"?: SellerDirectoryLayout_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"SellerDirectoryLayout_viewer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SellerDirectoryLayout_viewer",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SellerDirectoryContentModule_viewer"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SellersContainer_viewer"
    }
  ],
  "type": "Viewer",
  "abstractKey": null
};

(node as any).hash = "df9c0011da2cdb27c5d9eb114aa15a14";

export default node;
