import { type FC, useState, useEffect, useCallback } from 'react';
import { createRefetchContainer, graphql, type RelayRefetchProp } from 'react-relay/legacy';
import { FormattedMessage } from 'dibs-react-intl';
import { OverlaySpinner } from 'dibs-elements/exports/OverlaySpinner';
import { handleLocaleUrl, GLOBAL_CLIENT_ONLY_LOCALE } from 'dibs-intl/exports/urls';

import { MOBILE_TYPE, ALL_VERTICALS_KEY } from './helpers/constants';

import sellerDirectoryRootQuery from './sellerDirectoryRoot';

// Components
import SellerDirectoryContentModule from './components/SellerDirectoryContentModule/SellerDirectoryContentModule';
import SidebarContainer from './components/Sidebar/SidebarContainer';
import SidebarMobile from './components/Sidebar/SidebarMobile';
import SellersContainer from './components/SellersContainer/SellersContainer';

import { getPath } from './helpers/urlHelpers';

import styles from './SellerDirectoryLayout.scss';

import { type SellerDirectoryLayout_viewer$data } from './__generated__/SellerDirectoryLayout_viewer.graphql';

type QueryVars = {
    page: number;
    company: string;
    verticals: string;
    isTopSeller: boolean;
    isDistinguished: boolean;
};

type Props = {
    relay: RelayRefetchProp;
    viewer: SellerDirectoryLayout_viewer$data;
    devicetype: string;
    page: number;
    company?: string | null;
    verticals?: string | null;
    isTopSeller?: boolean;
    isDistinguished?: boolean;
};

const SellerDirectoryLayout: FC<Props> = ({
    relay,
    viewer,
    devicetype,
    page: initialPage,
    company: initialCompany,
    verticals: initialVerticals,
    isTopSeller: initialIsTopSeller,
    isDistinguished: initialIsDistinguished,
}) => {
    const [isLoading, setLoading] = useState<boolean>(false);
    const [isVisibleMobileSidebar, setVisibleMobileSidebar] = useState<boolean>(false);
    const [hasEventListener, setHasEventListener] = useState<boolean>(false);
    const [queryVariables, setQueryVars] = useState<QueryVars>(() => ({
        page: initialPage || 1,
        company: initialCompany || '',
        verticals: initialVerticals || '',
        isTopSeller: initialIsTopSeller || false,
        isDistinguished: initialIsDistinguished || false,
    }));

    const fetchNewResults = useCallback(
        (inputVars: Partial<QueryVars>, shouldSkipPushState: boolean = false) => {
            setLoading(true);

            const newQueryVars = { ...queryVariables, ...inputVars };
            setQueryVars(newQueryVars);

            if (!shouldSkipPushState) {
                const path = getPath(newQueryVars, newQueryVars.page);
                window.history.pushState(
                    newQueryVars,
                    '',
                    handleLocaleUrl(path, GLOBAL_CLIENT_ONLY_LOCALE)
                );
            }

            if (inputVars.verticals === ALL_VERTICALS_KEY) {
                newQueryVars.verticals = '';
            }

            relay.refetch(newQueryVars, null, (err: Error | null | undefined) => {
                if (err) {
                    // eslint-disable-next-line no-console
                    console.error('Caught error on refetch', err);
                }
                setLoading(false);
            });
        },
        [relay, queryVariables]
    );

    const historyListener = useCallback(
        // it'd be nice if you could provide a state shape type param, but it's not supported
        (event: PopStateEvent): void => {
            fetchNewResults(event.state, true);
        },
        [fetchNewResults]
    );

    useEffect(() => {
        if (!hasEventListener) {
            window.addEventListener('popstate', historyListener);
            setHasEventListener(true);
        }
        return () => {
            if (hasEventListener) {
                window.removeEventListener('popstate', historyListener);
            }
        };
    }, [hasEventListener, historyListener]);

    const onSearch = useCallback(
        (newSearchString?: string | null): void => {
            if (newSearchString === queryVariables.company) {
                return;
            }
            fetchNewResults({ company: newSearchString || '', page: 1 });
        },
        [fetchNewResults, queryVariables]
    );

    const onPageChange = (newPage: number): void => {
        if (newPage === queryVariables.page) {
            return;
        }
        fetchNewResults({ page: newPage });
        window.scrollTo(0, 0);
    };

    const onChangeSelectedVertical = (newVertical: string): void => {
        if (queryVariables.verticals === newVertical) {
            return;
        }
        fetchNewResults({ verticals: newVertical || ALL_VERTICALS_KEY, page: 1 });
    };

    const onToggleTopSellers = (newTopSeller: boolean): void => {
        if (newTopSeller === queryVariables.isTopSeller) {
            return;
        } else if (newTopSeller) {
            fetchNewResults({
                isTopSeller: newTopSeller,
                isDistinguished: false,
                page: 1,
                company: '',
                verticals: '',
            });
        } else {
            fetchNewResults({ isTopSeller: newTopSeller });
        }
    };

    const onToggleDistinguishedSellers = (newDistinguishedSeller: boolean): void => {
        if (newDistinguishedSeller === queryVariables.isDistinguished) {
            return;
        }
        fetchNewResults({ isDistinguished: newDistinguishedSeller, page: 1 });
    };

    return (
        <>
            <OverlaySpinner isOpen={isLoading} />
            <h1>
                <FormattedMessage
                    id="abm.sellerDirectory.title"
                    defaultMessage="Seller Directory"
                />
            </h1>
            <SellerDirectoryContentModule viewer={viewer} />
            <div className={`${styles.container} rowFlex`}>
                {devicetype === MOBILE_TYPE ? (
                    <SidebarMobile
                        company={queryVariables.company}
                        verticals={queryVariables.verticals}
                        isTopSeller={queryVariables.isTopSeller}
                        isDistinguished={queryVariables.isDistinguished}
                        isVisible={isVisibleMobileSidebar}
                        onChangeCompany={onSearch}
                        onChangeVerticals={onChangeSelectedVertical}
                        onChangeTopSeller={onToggleTopSellers}
                        onChangeDistinguished={onToggleDistinguishedSellers}
                        closeMobileSidebar={() => setVisibleMobileSidebar(false)}
                    />
                ) : (
                    <SidebarContainer
                        company={queryVariables.company}
                        verticals={queryVariables.verticals}
                        isTopSeller={queryVariables.isTopSeller}
                        isDistinguished={queryVariables.isDistinguished}
                        onChangeCompany={onSearch}
                        onChangeVerticals={onChangeSelectedVertical}
                        onChangeTopSeller={onToggleTopSellers}
                        onChangeDistinguished={onToggleDistinguishedSellers}
                    />
                )}
                <SellersContainer
                    viewer={viewer}
                    openMobileSidebar={() => setVisibleMobileSidebar(true)}
                    onPageChange={onPageChange}
                    page={queryVariables.page}
                    devicetype={devicetype}
                    company={queryVariables.company}
                    verticals={queryVariables.verticals}
                    isTopSeller={queryVariables.isTopSeller}
                    isDistinguished={queryVariables.isDistinguished}
                />
            </div>
        </>
    );
};

export default createRefetchContainer(
    SellerDirectoryLayout,
    {
        viewer: graphql`
            fragment SellerDirectoryLayout_viewer on Viewer {
                ...SellerDirectoryContentModule_viewer
                ...SellersContainer_viewer
            }
        `,
    },
    sellerDirectoryRootQuery
);
