import { type FC } from 'react';
import { createFragmentContainer, graphql } from 'react-relay/legacy';

import { GridRow, GridCol } from 'dibs-elements/exports/Grid';
import { ExpandingAreaWithReadMore } from 'dibs-elements/exports/ExpandingArea';

import { type SellerDirectoryContentModule_viewer$data } from './__generated__/SellerDirectoryContentModule_viewer.graphql';

import styles from './SellerDirectoryContentModule.scss';

export const REGULAR_ITEM_TYPE = 'topItem';

type Props = {
    viewer: SellerDirectoryContentModule_viewer$data;
};

const SellerDirectoryContentModule: FC<Props> = ({ viewer }) => {
    const contentModule = viewer?.browseContentModule?.top;

    if (!contentModule) {
        return null;
    }

    // only supporting "regular" items for CAM-39
    if (contentModule.type !== REGULAR_ITEM_TYPE) {
        return null;
    }

    return (
        <GridRow>
            <GridCol desktop={12} mobile={12}>
                <div className={styles.wrapper}>
                    {contentModule.mainImage ? (
                        <div className={styles.imageWrapper}>
                            <img className={styles.image} src={contentModule.mainImage} />
                        </div>
                    ) : null}
                    <div>
                        {contentModule.title && (
                            <div className={styles.title}>{contentModule.title}</div>
                        )}
                        {/* image height is 220px, "continue reading" is 40px" */}
                        {contentModule.description && (
                            <ExpandingAreaWithReadMore collapsedHeight="180px">
                                <div
                                    dangerouslySetInnerHTML={{ __html: contentModule.description }}
                                />
                            </ExpandingAreaWithReadMore>
                        )}
                    </div>
                </div>
            </GridCol>
        </GridRow>
    );
};

export default createFragmentContainer(SellerDirectoryContentModule, {
    viewer: graphql`
        fragment SellerDirectoryContentModule_viewer on Viewer {
            browseContentModule(
                pageName: "browse:seller-directory"
                pageId: $contentModulePreviewId
            ) {
                top {
                    type
                    title
                    description
                    mainImage
                }
            }
        }
    `,
});
