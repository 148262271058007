import { type FC } from 'react';
import { FormattedMessage } from 'dibs-react-intl';
import styles from './SellersHeader.scss';

import HeadingLevel from 'dibs-controlled-heading/exports/HeadingLevel';

type Props = {
    company: string;
    totalResults: number;
};

const SellersHeader: FC<Props> = ({ totalResults = 0, company = '' }) => {
    let title = (
        <FormattedMessage
            id="abm.sellerDirectory.sellerHeader.defaultTitle"
            defaultMessage="Browse all Sellers"
        />
    );

    if (totalResults === 0) {
        if (company) {
            title = (
                <span>
                    <FormattedMessage
                        id="abm.sellerDirectory.sellerHeader.noResultsSearch"
                        defaultMessage="0 results for <i>searchString</i>"
                        values={{
                            i: () => <i>{company}</i>,
                        }}
                    />
                </span>
            );
        } else {
            title = (
                <FormattedMessage
                    id="abm.sellerDirectory.sellerHeader.noResults"
                    defaultMessage="0 results"
                />
            );
        }
    }

    return (
        <div className={`${styles.container} rowFlex colXs12`}>
            <HeadingLevel>
                {Heading => <Heading className={styles.title}>{title}</Heading>}
            </HeadingLevel>
        </div>
    );
};

export default SellersHeader;
