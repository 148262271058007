/**
 * @generated SignedSource<<53b7cc0a7de6fb2e73b223c93cc7f501>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SellerTile_staticStorefrontBannerImage$data = ReadonlyArray<{
  readonly path: string;
  readonly " $fragmentType": "SellerTile_staticStorefrontBannerImage";
}>;
export type SellerTile_staticStorefrontBannerImage$key = ReadonlyArray<{
  readonly " $data"?: SellerTile_staticStorefrontBannerImage$data;
  readonly " $fragmentSpreads": FragmentRefs<"SellerTile_staticStorefrontBannerImage">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "SellerTile_staticStorefrontBannerImage",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "path",
      "storageKey": null
    }
  ],
  "type": "StaticStorefrontBannerImageType",
  "abstractKey": null
};

(node as any).hash = "bd71d507c2de9f4b85a45d4b4b2cd64c";

export default node;
