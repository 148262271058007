import { type ChangeEvent, type FC, useState, useEffect, type ReactNode } from 'react';
import classNames from 'classnames';

import Checkmark from 'dibs-icons/exports/legacy/Checkmark';
import styles from './main.scss';

type CheckboxProps = {
    checked?: boolean;
    children?: ReactNode;
    dataTn: string;
    disabled?: boolean;
    label?: ReactNode;
    labelClass?: string;
    maskForPrivacy?: boolean;
    name: string;
    onChange: (checked: boolean, e?: ChangeEvent<HTMLInputElement>) => void | null;
    type?: 'default' | 'circle';
    size?: 'default' | 'medium' | 'small';
    value?: string | number;
    wrapperClass?: string;
    hasError?: boolean;
};

export const Checkbox: FC<CheckboxProps> = props => {
    const {
        dataTn,
        wrapperClass,
        labelClass,
        name,
        label,
        maskForPrivacy,
        disabled,
        children,
        type = 'default',
        size = 'default',
        hasError,
        value,
    } = props;
    const [checked, setChecked] = useState(!!props.checked);

    useEffect(() => {
        setChecked(!!props.checked);
    }, [props.checked]);

    function onChange(e?: ChangeEvent<HTMLInputElement>): void {
        const { checked: nextChecked } = e?.currentTarget || {};

        /* istanbul ignore else */
        if (typeof nextChecked === 'boolean') {
            setChecked(nextChecked);
            props.onChange?.(nextChecked, e);
        }
    }

    const wrapperClasses = classNames(wrapperClass, {
        'fs-block': maskForPrivacy,
    });

    const labelContainerClassName = classNames(styles.container, {
        [styles.labelContainerDisabled]: disabled,
    });

    const labelClassName = classNames(styles.label, labelClass, {
        [styles.labelDisabled]: disabled,
        [styles.small]: size === 'small',
        [styles.medium]: size === 'medium',
    });

    const checkboxClassName = classNames(styles.checkbox, {
        [styles.checkboxChecked]: checked,
        [styles.checkboxUnchecked]: !checked,
        [styles.checkboxDisabled]: disabled,
        [styles.circleCheckbox]: type === 'circle',
        [styles.small]: size === 'small',
        [styles.checkboxError]: hasError,
    });

    const childClasses = classNames(styles.children, {
        [styles.childrenDisabled]: disabled,
    });

    const checkmarkClasses = classNames(styles.checkmark, {
        [styles.small]: size === 'small',
    });

    const id = name?.replace(/\s+/g, '-');

    return (
        <div className={wrapperClasses}>
            <input
                id={id}
                name={name}
                type={'checkbox'}
                className={styles.hiddenInput}
                checked={checked}
                disabled={disabled}
                onChange={onChange}
                value={value}
                data-tn={`${dataTn}-input`}
            />
            <label data-tn={`${dataTn}-label`} htmlFor={id} className={labelContainerClassName}>
                <div className={checkboxClassName}>
                    <Checkmark className={checkmarkClasses} />
                </div>
                {label && <div className={labelClassName}>{label}</div>}
            </label>
            {children && <div className={childClasses}>{children}</div>}
        </div>
    );
};
