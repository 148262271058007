import dibsCss from 'dibs-css';
import classnames from 'classnames';
import { type FunctionComponent } from 'react';

type RecognizedRibbonProps = {
    className?: string;
};

const RecognizedRibbon: FunctionComponent<RecognizedRibbonProps> = props => {
    return (
        <svg
            className={classnames(dibsCss.dibsIcon, props.className)}
            data-tn={'recognized-ribbon'}
            viewBox="0 0 250 250"
        >
            <path d="M196.3 135.8c8.8-13.7 13.9-30 13.9-47.5 0-48.7-39.6-88.3-88.3-88.3-48.7 0-88.3 39.6-88.3 88.3 0 19.3 6.2 37.1 16.7 51.6l-37.8 70.6 50.3-2.7 25 42.2 34.3-44.1 39.3 43.7 24.7-41.8 50.3 2.7-40.1-74.7zM42.6 88.3C42.6 44.6 78.2 9 121.9 9c43.7 0 79.3 35.6 79.3 79.3 0 40.6-30.7 74.2-70.1 78.7-2.7.3-5.3.4-8 .4-24.7 0-47.9-11.4-63.5-31.2l-4.3-5.4-.1.2c-8-12.3-12.6-27-12.6-42.7zM88.8 234l-21-35.5-39.8 2.2 28.5-53.1c16.2 17.8 39.5 29 65.4 29 3.9 0 7.8-.3 11.6-.8.2 0 .4 0 .6-.1L88.8 234zm92.2-35.5l-21.2 35.9-32.2-35.7 20-25.7c16.7-4.8 31.9-14.6 43.4-27.9l29.8 55.7-39.8-2.3z" />
        </svg>
    );
};
export default RecognizedRibbon;
