import { type FC, type ChangeEvent } from 'react';
import { FormattedMessage } from 'dibs-react-intl';
import { Checkbox } from 'dibs-elements/exports/Checkbox';
import { Link } from 'dibs-elements/exports/Link';
import { getPath } from '../../helpers/urlHelpers';

import { type SidebarProps } from '../Sidebar/Sidebar';

import styles from './SidebarFilter.scss';

const SidebarFilter: FC<SidebarProps> = ({
    company = '',
    verticals,
    isTopSeller = false,
    isDistinguished = false,
    onChangeTopSeller,
    onChangeDistinguished,
}) => {
    const recognizedSellersLink = getPath({
        company,
        isDistinguished: !isDistinguished,
        isTopSeller,
        verticals,
    });

    const topSellersLink = getPath({
        isDistinguished,
        isTopSeller: !isTopSeller,
        verticals,
    });

    const _onChangeRecognizedSeller = (
        checked: boolean,
        e?: ChangeEvent<HTMLInputElement>
    ): void => {
        if (e) {
            e.preventDefault();
        }
        onChangeDistinguished(checked);
    };

    const _onChangeTopSeller = (checked: boolean, e?: ChangeEvent<HTMLInputElement>): void => {
        if (e) {
            e.preventDefault();
        }
        onChangeTopSeller(checked);
    };

    return (
        <div>
            <Link href={recognizedSellersLink} dataTn="recognized-filter" className={styles.link}>
                <Checkbox
                    name="recognized"
                    checked={isDistinguished}
                    dataTn="recognized-filter-checkbox"
                    onChange={_onChangeRecognizedSeller}
                    label={
                        <FormattedMessage
                            id="abm.designerDirectory.filter.recognized"
                            defaultMessage="Recognized Sellers Only"
                        />
                    }
                    labelClass={styles.label}
                />
            </Link>
            <Link href={topSellersLink} dataTn="top-seller-filter" className={styles.link}>
                <Checkbox
                    name="topSeller"
                    checked={isTopSeller}
                    dataTn="top-seller-filter-checkbox"
                    onChange={_onChangeTopSeller}
                    label={
                        <FormattedMessage
                            id="abm.designerDirectory.filter.topSeller"
                            defaultMessage="Top Sellers Only"
                        />
                    }
                    labelClass={styles.label}
                />
            </Link>
        </div>
    );
};

export default SidebarFilter;
