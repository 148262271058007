import { type FC, useCallback, type MouseEvent, type ChangeEvent } from 'react';
import { useIntl } from 'dibs-react-intl';
import { Radio } from 'dibs-elements/exports/Radio';
import { Link } from 'dibs-elements/exports/Link';
import { keys } from 'dibs-ts-utils/exports/keys';
import { CATEGORY_MAP, ALL_VERTICALS_KEY } from '../../helpers/constants';
import { getPath } from '../../helpers/urlHelpers';

import { type SidebarProps } from '../Sidebar/Sidebar';

import styles from './SidebarCategory.scss';

const SidebarCategory: FC<SidebarProps> = ({
    verticals,
    onChangeVerticals,
    isDistinguished = false,
    isTopSeller = false,
}) => {
    const intl = useIntl();
    const onClick = useCallback(
        (event: MouseEvent) => {
            if (isTopSeller) {
                event.preventDefault();
            }
        },
        [isTopSeller]
    );
    const onChange = (value?: string | number, e?: ChangeEvent<HTMLInputElement>): void => {
        if (e) {
            e.preventDefault();
        }
        onChangeVerticals(value + '');
    };

    return (
        <div>
            {keys(CATEGORY_MAP).map(category => (
                <Link
                    key={category}
                    className={styles.link}
                    href={getPath({ vertical: category, verticals, isTopSeller, isDistinguished })}
                    onClick={onClick}
                    dataTn={`${category}-radio-button`}
                >
                    <Radio
                        name={category}
                        value={category}
                        dataTn={`category-${category}`}
                        label={intl.formatMessage(CATEGORY_MAP[category])}
                        wrapperClass={styles.radioWrapper}
                        labelClass={styles.radioLabel}
                        checked={
                            verticals === category || (category === ALL_VERTICALS_KEY && !verticals)
                        }
                        onChange={onChange}
                        disabled={isTopSeller}
                    />
                </Link>
            ))}
        </div>
    );
};

export default SidebarCategory;
