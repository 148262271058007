import { type FunctionComponent } from 'react';
import { Spinner } from '../Spinner';
import { ModalContainer } from '../ModalContainer';

import styles from './main.scss';

type OverlaySpinnerProps = {
    isOpen: boolean;
    dataTn?: string;
    onClose?: () => void;
    trapFocus?: boolean;
    preventBackScroll?: boolean;
};

export const OverlaySpinner: FunctionComponent<OverlaySpinnerProps> = props => {
    const { dataTn, isOpen = true, onClose, trapFocus, preventBackScroll } = props;
    const noop: () => void = () => {};

    return (
        <ModalContainer
            closeOnEsc={false}
            closeOnOutsideClick={false}
            dataTn={dataTn}
            isOpen={isOpen}
            onClose={onClose || noop}
            _INTERNAL_ONLY_CUSTOM_CONTENT_={<Spinner />}
            overlayClass={styles.suppressOutline}
            trapFocus={trapFocus}
            preventBackScroll={preventBackScroll}
        />
    );
};
